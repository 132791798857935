import styled from 'styled-components'

export default styled.div`
  .recharts-tooltip-wrapper {
    background: none;
    .tooltip-wrapper {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 5px;
      .each-tooltip-wrapper {
        .color-label {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .data-label {
          font-size: 12px;
        }
      }
      .time-label-wrapper {
        font-size: 16px;
      }
    }
  }
  .no-bar-data {
    width: 100%;
    height: 100%;
    font-family: 'Prompt-Medium';
    font-size: 18px;
  }
`
