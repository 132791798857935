import React from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import CardWrapper from '../CardWrapper/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'

import DestinationRankWrapperStyled from './styled'

import { withTranslation } from '../../../i18n'

const XAxisTickStyled = {
  fontFamily: 'Prompt',
  fontSize: 12,
  fill: '#fff',
  whiteSpace: 'nowrap',
}

export class DestinationRankWrapper extends React.PureComponent {
  renderDestinationRankingChart() {
    let output = null
    if (this.props.loading) {
      output = (
        <div className="no-bar-data flex justify-center align-items-center">
          <SkeletonAnimation id="contact-zone-skeleton-loading" />
        </div>
      )
    } else if (this.checkIsNoData()) {
      const noData = this.props.t('no_data')
      output = <div className="no-bar-data flex justify-center align-items-center">{noData}</div>
    } else {
      output = (
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <BarChart data={this.props.data} margin={{ top: 30, right: 50, left: 50, bottom: 15 }} layout="vertical">
            <CartesianGrid x={60} horizontal={false} style={{ opacity: 0.3, width: '100%' }} />
            <XAxis type="number" axisLine={false} tick={XAxisTickStyled} allowDecimals={false} />
            <YAxis dataKey="company" type="category" tick={<CustomizedAxisTick />} interval={0} width={151} />
            <Tooltip cursor={false} content={data => this.renderTooltip(data)} />
            {this.renderBars()}
          </BarChart>
        </ResponsiveContainer>
      )
    }
    return output
  }

  checkIsNoData() {
    const { data } = this.props
    const noDataCount = data.reduce((prev, currentValue) => {
      if (currentValue.count === 0) prev += 1
      return prev
    }, 0)
    return noDataCount === 5
  }

  renderBars() {
    const output = null
    if (!this.checkIsNoData()) {
      return <Bar key="company" barSize={16} dataKey="count" stackId="company" fill="#971214" legendType="none" />
    }
    return output
  }

  renderTooltip({ payload }) {
    let tooltipData = []
    let label = ''
    if (payload) {
      tooltipData = payload.map(eachData => {
        label = eachData.payload.company
        return (
          <div className="each-tooltip-wrapper flex align-items-center" key={eachData.dataKey}>
            <div className="color-label" style={{ backgroundColor: eachData.color }} />
            <div className="data-label">{`${eachData.dataKey}: ${eachData.value}`}</div>
          </div>
        )
      })
    }
    return (
      <div className="tooltip-wrapper flex flex-column">
        <div className="time-label-wrapper">{label}</div>
        {tooltipData}
      </div>
    )
  }

  render() {
    return (
      <DestinationRankWrapperStyled className="destination-rank">
        <CardWrapper title={this.props.t('top_5_contacted_companies_by_members')}>{this.renderDestinationRankingChart()}</CardWrapper>
      </DestinationRankWrapperStyled>
    )
  }
}

export const CustomizedAxisTick = props => {
  // eslint-disable-next-line react/prop-types
  const { x, y, payload } = props
  // eslint-disable-next-line react/prop-types
  if (payload.value.length > 12) {
    // eslint-disable-next-line react/prop-types
    payload.value = payload.value.slice(0, 12).concat('...')
  }

  const checkData = data => {
    if (data === '2nd' || data === '3rd' || data === '4th' || data === '5th') return true
    return false
  }

  return (
    <g transform={`translate(${x - 151},${y - 10})`}>
      {/* eslint-disable-next-line react/prop-types */}
      <text x={0} y={0} dy={16} fontFamily="Prompt" fontSize="16" fill={checkData(payload.value) ? '#6D7278' : '#ffffff'}>
        {/* eslint-disable-next-line react/prop-types */}
        {payload.value}
      </text>
    </g>
  )
}

DestinationRankWrapper.defaultProps = {
  loading: false,
}

DestinationRankWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      company: PropTypes.string,
      count: PropTypes.number,
    }),
    PropTypes.array,
  ]),
  loading: PropTypes.bool,
}

export default withTranslation()(DestinationRankWrapper)
